<template>
  <div>
    <Head title="Создать Заявку" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="$routes.orders()"
      >
        Заявки
      </Link>
      <span class="font-medium text-indigo-400">/</span> Создать
    </h1>
    <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
      <order-form
        v-model="form"
        :clients="clients"
        :workers="workers"
        :categories="categories"
        :statuses="statuses"
        @submit="form.post($routes.orders())"
      >
        <div
          class="flex items-center justify-end border-t border-gray-200 bg-gray-100 px-8 py-4"
        >
          <loading-button
            :loading="form.processing"
            class="btn-indigo"
            type="submit"
          >
            Создать Заявку
          </loading-button>
        </div>
      </order-form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import OrderForm from './Form.vue';
import omit from 'lodash/omit';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    OrderForm,
  },
  layout: Layout,
  props: {
    order: {
      type: Object,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    workers: {
      type: Array,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        order: omit(this.order, 'id', 'deleted_at'),
      }),
    };
  },
};
</script>
