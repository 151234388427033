<template>
  <house-form v-model="form" class="max-w-3xl bg-white" @submit="submit">
    <div
      class="flex items-center justify-end bhouse-t bhouse-gray-200 bg-gray-100 px-8 py-4"
    >
      <loading-button
        :loading="form.processing"
        class="btn-indigo"
        type="submit"
      >
        Добавить Дом
      </loading-button>
    </div>
  </house-form>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue';
import HouseForm from './Form.vue';

export default {
  components: {
    LoadingButton,
    HouseForm,
  },
  remember: 'form',
  emits: ['success'],
  data() {
    return {
      form: this.$inertia.form({
        house: {},
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.$routes.houses(), {
        preserveState: (page) =>
          page.props.errors && Object.keys(page.props.errors).length,
        preserveScroll: (page) =>
          page.props.errors && Object.keys(page.props.errors).length,
        onSuccess: () => {
          this.$emit('success');
          this.form.reset('house');
        },
      });
    },
  },
};
</script>
