<template>
  <div class="overflow-x-auto rounded bg-white shadow">
    <table class="w-full whitespace-nowrap">
      <thead>
        <tr class="text-left font-bold">
          <th class="px-6 pb-4 pt-6">№</th>
          <th class="px-6 pb-4 pt-6">Собственник</th>
          <th class="px-6 pb-4 pt-6">Адрес</th>
          <th class="px-6 pb-4 pt-6">Создано</th>
          <th class="px-6 pb-4 pt-6">Сделать до</th>
          <th class="px-6 pb-4 pt-6">Статус</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="order in orders"
          :key="order.id"
          class="focus-within:bg-gray-100 hover:bg-gray-100"
        >
          <td class="border-t max-w-24 truncate">
            <Link
              class="flex items-center px-6 py-4 focus:text-indigo-500"
              :href="$routes.edit_order(order.id)"
              aria-label="Edit"
            >
              № {{ order.id }}
              <icon
                v-if="order.deleted_at"
                name="trash"
                class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-500"
              />
            </Link>
          </td>
          <td class="border-t">
            <Link
              class="flex items-center px-6 py-4"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              {{ order.client_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link
              class="flex items-center px-6 py-4"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              {{ order.address }}
            </Link>
          </td>

          <td class="border-t">
            <Link
              class="flex items-center px-6 py-4"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              {{ $filters.formatDate(order.created_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link
              class="flex items-center px-6 py-4"
              :class="{ 'text-red-600': order['stale?'] === true }"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              {{ $filters.formatDate(order.finish_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link
              class="flex items-center px-6 py-4"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              {{ $filters.status(order.status) }}
            </Link>
          </td>
          <td class="w-px border-t">
            <Link
              class="flex items-center px-4"
              :href="$routes.edit_order(order.id)"
              tabindex="-1"
              aria-label="Edit"
            >
              <icon name="cheveron-right" class="block h-6 w-6 fill-gray-500" />
            </Link>
          </td>
        </tr>
        <tr v-if="orders.length === 0">
          <td class="border-t px-6 py-4" colspan="4">Нет заявок.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';

export default {
  components: {
    Link,
    Icon,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
};
</script>
