<template>
  <client-form
    v-model="form"
    :houses="houses"
    class="max-w-3xl bg-white"
    @submit="submit"
  >
    <div
      class="flex items-center justify-end border-t border-gray-200 bg-gray-100 px-8 py-4"
    >
      <loading-button
        :loading="form.processing"
        class="btn-indigo"
        type="submit"
      >
        Добавить Собственника
      </loading-button>
    </div>
  </client-form>
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton.vue';
import ClientForm from './Form.vue';

export default {
  components: {
    LoadingButton,
    ClientForm,
  },
  props: {
    houses: {
      type: Array,
      required: true,
    },
  },
  remember: 'form',
  emits: ['success'],
  data() {
    return {
      form: this.$inertia.form({
        client: {},
      }),
    };
  },
  methods: {
    submit() {
      this.form.post(this.$routes.clients(), {
        preserveState: (page) =>
          page.props.errors && Object.keys(page.props.errors).length,
        preserveScroll: (page) =>
          page.props.errors && Object.keys(page.props.errors).length,
        onSuccess: () => {
          this.$emit('success');
          this.form.reset('client');
        },
      });
    },
  },
};
</script>
