<template>
  <div>
    <Head :title="`${form.worker.first_name} ${form.worker.last_name}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-500 hover:text-indigo-800"
        :href="$routes.workers()"
      >
        Исполнители
      </Link>
      <span class="font-medium text-indigo-400">/</span>
      {{ form.worker.first_name }} {{ form.worker.last_name }}
    </h1>
    <trashed-message v-if="worker.deleted_at" class="mb-6" @restore="restore">
      Исполнитель был удален.
    </trashed-message>
    <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
      <worker-form v-model="form" @submit="form.put($routes.worker(worker.id))">
        <div
          class="flex items-center bworker-t bworker-gray-200 bg-gray-100 px-8 py-4"
        >
          <button
            v-if="!worker.deleted_at"
            class="text-red-700 hover:underline"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            Удалить
          </button>
          <loading-button
            :loading="form.processing"
            class="btn-indigo ml-auto"
            type="submit"
          >
            Обновить
          </loading-button>
        </div>
      </worker-form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import WorkerForm from './Form.vue';
import TrashedMessage from '@/Shared/TrashedMessage.vue';
import omit from 'lodash/omit';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    WorkerForm,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    worker: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        worker: {
          ...omit(this.worker, 'id', 'deleted_at', 'photo'),
        },
      }),
    };
  },
  methods: {
    destroy() {
      if (confirm('Вы хотите удалить исполнителя?')) {
        this.$inertia.delete(this.$routes.worker(this.worker.id));
      }
    },
    restore() {
      if (confirm('Вы хотите восстановить исполнителя?')) {
        this.$inertia.put(this.$routes.restore_worker(this.worker.id));
      }
    },
  },
};
</script>
