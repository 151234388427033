// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '~/styles/application.css';

import { createApp, h } from 'vue';

import { createInertiaApp } from '@inertiajs/vue3';
import PrimeVue from 'primevue/config';
import Wind from '@/presets/wind';
import Locale from '@/locale/ru.json';

import * as Routes from '@/routes.js';
import moment from 'moment';

const pages = import.meta.glob('../Pages/**/*.vue', { eager: true });

const Consts = {
  dateFormat: 'dd.mm.yy',
  calDateFormat: 'yy-mm-dd',
};

const Filters = {
  formatDateWithoutTime(value) {
    return moment(String(value)).format('DD.MM.YYYY');
  },
  formatDate(value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  },

  parseDate(value) {
    return moment(value).toDate();
  },

  formatRawDate(value) {
    return moment(value).format('YYYY-MM-DD');
  },
  status(value) {
    switch (value) {
      case 'initial':
        return 'Новая';
      case 'in_progress':
        return 'В работе';
      case 'done':
        return 'Завершена';
    }
  },
};

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(
        `Unknown page ${name}. Is it located under Pages with a .vue extension?`,
      );

    return component;
  },

  title: (title) => (title ? `${title} - Ping CRM` : 'Ping CRM'),

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({
      render: () => h(App, props),
    });
    vueApp.config.globalProperties.$routes = Routes;
    vueApp.config.globalProperties.$filters = Filters;
    vueApp.config.globalProperties.$consts = Consts;
    vueApp.use(PrimeVue, { unstyled: true, pt: Wind, locale: Locale['ru'] });
    vueApp.use(plugin).mount(el);
  },
});
