<template>
  <div>
    <Head :title="`${house.address}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-500 hover:text-indigo-800"
        :href="$routes.houses()"
      >
        Дома
      </Link>
      <span class="font-medium text-indigo-400">/</span>
      {{ house.address }}
    </h1>
    <trashed-message v-if="house.deleted_at" class="mb-6" @restore="restore">
      Дом был удален.
    </trashed-message>
    <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
      <house-form v-model="form" @submit="form.put($routes.house(house.id))">
        <div
          class="flex items-center bhouse-t bhouse-gray-200 bg-gray-100 px-8 py-4"
        >
          <button
            v-if="!house.deleted_at"
            class="text-red-700 hover:underline"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            Удалить
          </button>
          <loading-button
            :loading="form.processing"
            class="btn-indigo ml-auto"
            type="submit"
          >
            Обновить
          </loading-button>
        </div>
      </house-form>
    </div>
    <clients :clients="clients" :house="house" :filters="filters" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import HouseForm from './Form.vue';
import TrashedMessage from '@/Shared/TrashedMessage.vue';
import omit from 'lodash/omit';
import Clients from './_Clients.vue';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    HouseForm,
    TrashedMessage,
    Clients,
  },
  layout: Layout,
  props: {
    clients: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    house: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        house: {
          ...omit(this.house, 'id', 'deleted_at'),
        },
      }),
    };
  },
  methods: {
    destroy() {
      if (confirm('Вы хотите удалить дом?')) {
        this.$inertia.delete(this.$routes.house(this.house.id));
      }
    },
    restore() {
      if (confirm('Вы хотите восстановить дом?')) {
        this.$inertia.put(this.$routes.restore_house(this.house.id));
      }
    },
  },
};
</script>
