<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.client.name"
        :errors="form.errors.name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="ФИО"
      />

      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <label class="form-label" for="house_id"> Дом: </label>
        <auto-complete
          id="house_id"
          v-model="formattedHouse"
          class="w-full"
          :delay="0"
          placeholder="Выберите дом"
          :invalid="form.errors.house_id?.length > 0"
          :suggestions="filteredHouses"
          dropdown
          @complete="search"
        >
          <template #option="slotProps">
            <div class="flex flex-col gap-0">
              <div class="font-medium">{{ slotProps.option.address }}</div>
            </div>
          </template>
        </auto-complete>
        <div v-if="form.errors.house_id" class="form-error">
          {{ form.errors.house_id[0] }}
        </div>
      </div>

      <text-input
        v-model="form.client.phone"
        :errors="form.errors.phone"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Телефон (89112223344)"
      />
      <text-input
        v-model="form.client.apartment"
        :errors="form.errors.apartment"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Номер квартиры"
      />
      <text-input
        v-model="form.client.email"
        :errors="form.errors.email"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Email"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import TextInput from '@/Shared/TextInput.vue';
import AutoComplete from 'primevue/autocomplete';

export default {
  components: {
    TextInput,
    AutoComplete,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    houses: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  data() {
    return {
      filteredHouses: [],
      houseFilter: '',
    };
  },
  computed: {
    formattedHouse: {
      // getter
      get: function () {
        const house = this.houses.find(
          (house) => house.id === this.form.client.house_id,
        );
        return house?.address || this.houseFilter;
      },
      // setter
      set: function (newValue) {
        this.houseFilter = newValue;
        this.form.client.house_id = newValue.id;
      },
    },
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    search: function (event) {
      this.houseFilter = event.query;

      if (!event.query.trim().length) {
        this.filteredHouses = [...this.houses];
      } else {
        const filter = event.query.toLowerCase();
        this.filteredHouses = this.houses.filter((house) =>
          house.address.toLowerCase().includes(filter),
        );
      }
    },
  },
};
</script>
