<template>
  <div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.root()"
        role="navigation"
        aria-label="Dashboard"
      >
        <icon
          name="dashboard"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('') ? 'text-white' : 'text-indigo-300 group-hover:text-white'
          "
        >
          Главная
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.orders()"
        role="navigation"
        aria-label="Orders"
      >
        <icon
          name="office"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('orders')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('orders')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Заявки
        </div>
      </Link>
    </div>
    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.clients()"
        role="navigation"
        aria-label="Clients"
      >
        <icon
          name="users"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('clients')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('clients')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Собственники
        </div>
      </Link>
    </div>

    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.workers()"
        role="navigation"
        aria-label="Workers"
      >
        <icon
          name="users"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('workers')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('workers')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Исполнители
        </div>
      </Link>
    </div>

    <div class="mb-4">
      <Link
        class="group flex items-center py-3"
        :href="$routes.houses()"
        role="navigation"
        aria-label="Houses"
      >
        <icon
          name="users"
          class="mr-2 h-4 w-4"
          :class="
            isUrl('houses')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('houses')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Дома
        </div>
      </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';

export default {
  components: {
    Icon,
    Link,
  },
  methods: {
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1);

      if (urls[0] === '') {
        return currentUrl === '';
      }

      return urls.filter((url) => currentUrl.startsWith(url)).length;
    },
  },
};
</script>
