<template>
  <div>
    <Head title="Create Worker" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="$routes.workers()"
      >
        Исполнители
      </Link>
      <span class="font-medium text-indigo-400">/</span> Добавить
    </h1>
    <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
      <worker-form v-model="form" @submit="form.post($routes.workers())">
        <div
          class="flex items-center justify-end bworker-t bworker-gray-200 bg-gray-100 px-8 py-4"
        >
          <loading-button
            :loading="form.processing"
            class="btn-indigo"
            type="submit"
          >
            Добавить Исполнителя
          </loading-button>
        </div>
      </worker-form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import WorkerForm from './Form.vue';

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    WorkerForm,
  },
  layout: Layout,
  props: {
    worker: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        worker: this.worker,
      }),
    };
  },
};
</script>
