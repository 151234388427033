<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Главная</h1>

    <div class="grid grid-cols-2 gap-6">
      <div class="overflow-hidden rounded bg-white shadow p-8">
        <h2 class="mb-6">Общая статистика</h2>
        <total-stats :stats="stats.total" />
      </div>

      <div class="overflow-hidden rounded bg-white shadow p-8">
        <h2 class="mb-6">Заявки за текущий год</h2>
        <year-stats
          :datasets="setChartData(stats.year)"
          :options="chartOptions"
        />
      </div>
    </div>

    <div class="overflow-hidden rounded bg-white shadow p-8 mt-6">
      <h2 class="mb-6">Последние заявки</h2>
      <orders :orders="orders" />
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import YearStats from './_YearStats.vue';
import Orders from './_Orders.vue';
import TotalStats from './_TotalStats.vue';
import { toRaw } from 'vue';

export default {
  components: {
    Head,
    YearStats,
    Orders,
    TotalStats,
  },
  layout: Layout,
  props: {
    stats: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: this.setChartOptions(),
    };
  },
  methods: {
    setChartData: (stats) => {
      stats = toRaw(stats);

      return [
        {
          label: 'Новые',
          backgroundColor: '#a0d1f6',
          borderColor: '#83ADCD',
          data: stats.initial,
        },
        {
          label: 'В работе',
          backgroundColor: '#ffd0a3',
          borderColor: '#CCA682',
          data: stats.in_progress,
        },
        {
          label: 'Сделано',
          backgroundColor: '#82BD60',
          borderColor: '#6E9C53',
          data: stats.done,
        },
      ];
    },
    setChartOptions: () => {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue(
        '--text-color-secondary',
      );
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      return {
        maintainAspectRatio: true,
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false,
            },
          },
        },
      };
    },
  },
};
</script>
