<template>
  <div>
    <Head title="Orders" />
    <h1 class="mb-8 text-3xl font-bold">Заявки</h1>
    <div class="mb-6 flex items-center justify-between">
      <search-filter
        v-model="form.search"
        class="mr-4 w-full max-w-md"
        @reset="reset"
      >
        <label class="block text-gray-800" for="house-filter"> Дом:</label>
        <auto-complete
          id="house_id"
          v-model="formattedHouse"
          class="w-full mt-1"
          :delay="0"
          placeholder="Выберите дом"
          :suggestions="filteredHouses"
          dropdown
          @complete="searchHouse"
        >
          <template #option="slotProps">
            <div class="flex flex-col gap-0">
              <div class="font-medium">{{ slotProps.option.address }}</div>
            </div>
          </template>
        </auto-complete>

        <label class="block text-gray-800 mt-6" for="trashed-filter">
          Удаленные:
        </label>
        <select
          id="trashed-filter"
          v-model="form.trashed"
          class="form-select mt-1 w-full"
        >
          <option :value="null" />
          <option value="with">С Удаленными</option>
          <option value="only">Только Удаленные</option>
        </select>
        <label class="block text-gray-800 mt-6 mb-1" for="expired-filter">
          Период:
        </label>
        <calendar
          id="date-filter"
          v-model="formattedRange"
          class="w-full"
          selection-mode="range"
          :manual-input="false"
          :date-format="$consts.dateFormat"
          show-button-bar
        />

        <label class="block text-gray-800 mt-6" for="date-filter">
          Просроченные:
        </label>

        <select
          id="expired-filter"
          v-model="form.expired"
          class="form-select mt-1 w-full"
        >
          <option :value="null" />
          <option value="without">Без Просроченных</option>
          <option value="only">Только Просроченные</option>
        </select>
      </search-filter>
      <button class="btn-indigo mr-auto" type="button" @click="exportOrders">
        Экспорт
      </button>
      <Link class="btn-indigo" :href="$routes.new_order()">
        Создать <span class="hidden md:inline">Заявку</span>
      </Link>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-6 pb-4 pt-6">№</th>
            <th class="px-6 pb-4 pt-6">Собственник</th>
            <th class="px-6 pb-4 pt-6">Адрес</th>
            <th class="px-6 pb-4 pt-6">Категория</th>
            <th class="px-6 pb-4 pt-6">Создано</th>
            <th class="px-6 pb-4 pt-6">Сделать до</th>
            <th class="px-6 pb-4 pt-6">Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order in orders.data"
            :key="order.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t max-w-24 truncate">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="$routes.edit_order(order.id)"
                aria-label="Edit"
              >
                № {{ order.id }}
                <icon
                  v-if="order.deleted_at"
                  name="trash"
                  class="ml-2 h-3 w-3 flex-shrink-0 fill-gray-500"
                />
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-left px-6 py-4 flex-col gap-2"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                <div>
                  {{ order.client.name }}
                </div>
                <div class="text-indigo-600">
                  {{ order.client.phone }}
                </div>
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ order.client.address }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 text-wrap"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ order.category.title }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ $filters.formatDate(order.created_at) }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :class="{ 'text-red-600': order['stale?'] === true }"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ $filters.formatDate(order.finish_at) }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ $filters.status(order.status) }}
              </Link>
            </td>
            <td class="w-px border-t">
              <Link
                class="flex items-center px-4"
                :href="$routes.edit_order(order.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                <icon
                  name="cheveron-right"
                  class="block h-6 w-6 fill-gray-500"
                />
              </Link>
            </td>
          </tr>
          <tr v-if="orders.data.length === 0">
            <td class="border-t px-6 py-4" colspan="7">Нет заявок.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :meta="orders.meta" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import Layout from '@/Layouts/Main.vue';
import mapValues from 'lodash/mapValues';
import Pagination from '@/Shared/Pagination.vue';
import pickBy from 'lodash/pickBy';
import SearchFilter from '@/Shared/SearchFilter.vue';
import throttle from 'lodash/throttle';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';

export default {
  components: {
    Head,
    Link,
    Icon,
    Calendar,
    Pagination,
    AutoComplete,
    SearchFilter,
  },
  layout: Layout,
  props: {
    orders: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    houses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filteredHouses: [],
      houseFilter: '',
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        expired: this.filters.expired,
        range: this.filters.range,
        house_id: this.filters.house_id,
      },
    };
  },
  computed: {
    formattedHouse: {
      // getter
      get: function () {
        const house = this.houses.find(
          (house) => house.id === this.form.house_id,
        );
        return house?.address || this.houseFilter;
      },
      // setter
      set: function (newValue) {
        this.houseFilter = newValue;
        this.form.house_id = newValue.id;
      },
    },
    formattedRange: {
      // getter
      get: function () {
        return this.form.range
          ?.filter(Boolean)
          ?.map((date) => this.$filters.parseDate(date));
      },
      // setter
      set: function (newValue) {
        if (newValue == null) {
          this.form.range = null;
          return;
        }
        const range = newValue
          .filter(Boolean)
          .map((date) => this.$filters.formatRawDate(date));
        this.form.range = range;
      },
    },
  },
  watch: {
    form: {
      handler: throttle(function () {
        let query = pickBy(this.form);

        this.$inertia.get(
          this.$routes.orders(
            Object.keys(query).length ? query : { remember: 'forget' },
          ),
          {},
          {
            preserveState: true,
            preserveScroll: true,
            replace: true,
            only: ['orders'],
          },
        );
      }, 150),
      deep: true,
    },
  },
  methods: {
    searchHouse: function (event) {
      this.houseFilter = event.query;

      if (!event.query.trim().length) {
        this.filteredHouses = [...this.houses];
      } else {
        const filter = event.query.toLowerCase();
        this.filteredHouses = this.houses.filter((house) =>
          house.address.toLowerCase().includes(filter),
        );
      }
    },
    exportOrders() {
      const query = pickBy(this.form);
      const params = Object.keys(query).length ? query : '';
      window.open(this.$routes.export_orders(params));
    },
    reset() {
      this.form = mapValues(this.form, () => null);
    },
  },
};
</script>
