<template>
  <div>
    <Head :title="`${form.client.name}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-indigo-500 hover:text-indigo-800"
        :href="$routes.clients()"
      >
        Собственники
      </Link>
      <span class="font-medium text-indigo-400">/</span>
      {{ form.client.name }}
    </h1>
    <trashed-message v-if="client.deleted_at" class="mb-6" @restore="restore">
      Клиент был удален.
    </trashed-message>
    <div class="flex flex-row gap-8">
      <div class="overflow-hidden rounded bg-white shadow basis-2/3">
        <client-form
          v-model="form"
          :houses="houses"
          @submit="form.put($routes.client(client.id))"
        >
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <button
              v-if="!client.deleted_at"
              class="text-red-700 hover:underline"
              tabindex="-1"
              type="button"
              @click="destroy"
            >
              Удалить
            </button>
            <loading-button
              :loading="form.processing"
              class="btn-indigo ml-auto"
              type="submit"
            >
              Обновить
            </loading-button>
          </div>
        </client-form>
      </div>
      <div class="overflow-hidden rounded bg-white shadow h-4/5 basis-1/3">
        <stats :client="client" />
      </div>
    </div>
    <orders
      :orders="orders"
      :workers="workers"
      :categories="categories"
      :statuses="statuses"
      :filters="filters"
      :new-order="order"
      :client="client"
      :clients="clients"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import ClientForm from './Form.vue';
import TrashedMessage from '@/Shared/TrashedMessage.vue';
import omit from 'lodash/omit';
import Orders from './_Orders.vue';
import Stats from './_Stats.vue';

export default {
  components: {
    Head,
    Link,
    Stats,
    Orders,
    LoadingButton,
    ClientForm,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    order: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    workers: {
      type: Array,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    houses: {
      type: Array,
      required: true,
    },
    orders: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        client: omit(this.client, 'id', 'deleted_at'),
      }),
    };
  },
  methods: {
    destroy() {
      if (confirm('Вы хотите удалить собственника?')) {
        this.$inertia.delete(this.$routes.client(this.client.id));
      }
    },
    restore() {
      if (confirm('Вы хотите восстановить собственника?')) {
        this.$inertia.put(this.$routes.restore_client(this.client.id));
      }
    },
  },
};
</script>
