<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.order.title"
        :errors="form.errors.title"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Заявка"
      />

      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <label class="form-label" for="client_id"> Собственник: </label>
        <auto-complete
          id="client_id"
          v-model="formattedClient"
          class="w-full"
          :delay="0"
          dropdown
          placeholder="Выберите собственника"
          :invalid="form.errors.client_id?.length > 0"
          :suggestions="filteredClients"
          @complete="searchClient"
        >
          <template #option="slotProps">
            <div class="flex flex-col gap-0">
              <div class="font-medium">{{ slotProps.option.name }}</div>
              <div class="font-light">{{ slotProps.option.address }}</div>
              <div>{{ slotProps.option.phone }}</div>
            </div>
          </template>
        </auto-complete>
        <div v-if="form.errors.client_id" class="form-error">
          {{ form.errors.client_id[0] }}
        </div>
      </div>

      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <label class="form-label" for="category_id"> Категория: </label>
        <auto-complete
          id="category_id"
          v-model="formattedCategory"
          option-group-label="label"
          option-group-children="items"
          class="w-full"
          :delay="0"
          dropdown
          placeholder="Выберите категорию"
          :invalid="form.errors.category_id?.length > 0"
          :suggestions="filteredCategories"
          @complete="searchCategory"
        >
          <template #option="slotProps">
            <div class="flex flex-col gap-0">
              {{ slotProps.option.title }}
              <div class="font-light">
                Интервал: {{ slotProps.option.interval }}ч.
              </div>
              <div>{{ slotProps.option.section }}</div>
            </div>
          </template>
        </auto-complete>
        <div v-if="form.errors.category_id" class="form-error">
          {{ form.errors.category_id[0] }}
        </div>
      </div>

      <select-input
        v-model="form.order.worker_id"
        :errors="form.errors.worker_id"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Исполнитель"
      >
        <option v-for="worker in workers" :key="worker.id" :value="worker.id">
          {{ worker.name }}
        </option>
      </select-input>

      <select-input
        v-model="form.order.status"
        :errors="form.errors.status"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Статус"
      >
        <option v-for="status in statuses" :key="status" :value="status">
          {{ $filters.status(status) }}
        </option>
      </select-input>

      <div class="w-full pb-8 pr-6 lg:w-1/2">
        <label class="form-label" for="client_id"> Создано: </label>
        <calendar
          id="calendar-24h"
          v-model="formattedCreatedAt"
          show-time
          :step-minute="5"
          :hour-format="'24'"
          :date-format="$consts.dateFormat"
        />
      </div>
    </div>

    <slot />
  </form>
</template>

<script>
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import AutoComplete from 'primevue/autocomplete';
import Calendar from 'primevue/calendar';

export default {
  components: {
    SelectInput,
    TextInput,
    AutoComplete,
    Calendar,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    workers: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  data() {
    return {
      filteredClients: [],
      filteredCategories: [],
      clientFilter: '',
      categoryFilter: '',
    };
  },
  computed: {
    formattedCategory: {
      // getter
      get: function () {
        for (const group of this.categories) {
          const category = group.items.find(
            (category) => category.id === this.form.order.category_id,
          );
          if (category) return category.title;
        }

        return this.categoryFilter;
      },
      // setter
      set: function (newValue) {
        this.categoryFilter = newValue;
        this.form.order.category_id = newValue.id;
      },
    },

    formattedClient: {
      // getter
      get: function () {
        const client = this.clients.find(
          (client) => client.id === this.form.order.client_id,
        );
        return client?.name || this.clientFilter;
      },
      // setter
      set: function (newValue) {
        this.clientFilter = newValue;
        this.form.order.client_id = newValue.id;
      },
    },

    formattedCreatedAt: {
      // getter
      get: function () {
        if (this.form.order.created_at instanceof Date) {
          return this.form.order.created_at;
        }
        return this.$filters.formatDate(this.form.order.created_at) || '';
      },
      // setter
      set: function (newValue) {
        this.form.order.created_at = newValue;
      },
    },

    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },

  methods: {
    searchClient: function (event) {
      if (!event.query.trim().length) {
        this.filteredClients = [...this.clients];
      } else {
        const filter = event.query.toLowerCase();
        this.clientFilter = event.query;
        this.filteredClients = this.clients.filter(
          (client) =>
            client.name.toLowerCase().includes(filter) ||
            client.address.toLowerCase().includes(filter) ||
            client.phone.includes(filter),
        );
      }
    },

    searchCategory: function (event) {
      if (!event.query.trim().length) {
        this.filteredCategories = [...this.categories];
      } else {
        const filter = event.query.toLowerCase();
        this.categoryFilter = event.query;
        this.filteredCategories = this.categories.filter(
          (group) =>
            group.items.filter((cat) =>
              cat.title.toLowerCase().includes(filter),
            ).length > 0,
        );
      }
    },
  },
};
</script>
