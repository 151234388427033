<template>
  <div class="flex flex-col h-full justify-between">
    <div class="flex p-8 flex-col gap-6">
      <div>Всего заявок: {{ stats.total }}</div>
      <div>Новые: {{ stats.initial }}</div>
      <div>В работе: {{ stats.in_progress }}</div>

      <div>Сделано: {{ stats.done }}</div>
      <div>Просрочено: {{ stats.expired }}</div>
    </div>
    <div class="flex border-t border-gray-200 bg-gray-100 px-8 py-4">
      <button class="btn-indigo ml-auto" type="button" @click="exportOrders">
        Экспорт
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stats: this.client.orders_stats,
    };
  },
  methods: {
    exportOrders() {
      window.open(this.$routes.export_client(this.client.id));
    },
  },
};
</script>
