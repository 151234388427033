<template>
  <div class="flex flex-row">
    <div class="flex flex-col gap-4 whitespace-nowrap">
      <div>Новые: {{ stats.initial }}</div>
      <div>В работе: {{ stats.in_progress }}</div>
      <div>Сделано: {{ stats.done }}</div>
      <div></div>
      <div>Всего: {{ stats.total }}</div>
      <div>Просрочено: {{ stats.expired }}</div>
    </div>
    <chart
      type="pie"
      :data="chartData"
      :options="options"
      class="w-full h-30rem"
    />
  </div>
</template>

<script>
import Chart from 'primevue/chart';
import { toRaw } from 'vue';

export default {
  components: {
    Chart,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: this.setChartData(),
      options: this.setChartOptions(),
    };
  },
  methods: {
    setChartOptions: () => {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');

      return {
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
      };
    },
    setChartData: function () {
      const stats = toRaw(this.stats);

      return {
        labels: ['Новые', 'В работе', 'Сделано', 'Просрочено'],
        datasets: [
          {
            data: [stats.initial, stats.in_progress, stats.done, stats.expired],
            backgroundColor: ['#a0d1f6', '#ffd0a3', '#82BD60', '#F7AEA4'],
            hoverBackgroundColor: ['#83ADCD', '#CCA682', '#6E9C53', '#B47C74'],
          },
        ],
      };
    },
  },
};
</script>
