<template>
  <chart type="bar" :data="chartData" :options="options" class="h-30rem" />
</template>

<script>
import Chart from 'primevue/chart';

export default {
  components: {
    Chart,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: this.setChartData(),
    };
  },
  methods: {
    setChartData: function () {
      return {
        labels: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        datasets: this.datasets,
      };
    },
  },
};
</script>
