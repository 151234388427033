<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.house.address"
        :errors="form.errors.address"
        class="w-full pb-8 pr-6"
        label="Адрес"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import TextInput from '@/Shared/TextInput.vue';

export default {
  components: {
    TextInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
