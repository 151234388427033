<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <text-input
        v-model="form.worker.first_name"
        :errors="form.errors.first_name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Имя"
      />
      <text-input
        v-model="form.worker.last_name"
        :errors="form.errors.last_name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Фамилия"
      />
      <text-input
        v-model="form.worker.email"
        :errors="form.errors.email"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Email"
      />
      <text-input
        v-model="form.worker.password"
        :errors="form.errors.password"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="password"
        autocomplete="new-password"
        label="Пароль"
      />
      <text-input
        v-model="form.worker.phone"
        :errors="form.errors.phone"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Телефон"
      />
      <text-input
        v-model="form.worker.position"
        :errors="form.errors.position"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Должность"
      />

      <file-input
        v-model="form.worker.photo"
        :errors="form.errors.photo"
        class="w-full pb-8 pr-6 lg:w-1/2"
        type="file"
        accept="image/*"
        label="Фото"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import TextInput from '@/Shared/TextInput.vue';
import FileInput from '@/Shared/FileInput.vue';

export default {
  components: {
    TextInput,
    FileInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
